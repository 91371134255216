import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Container,
    Grid,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import PageTitle from "../../hooks/page-title";
import { InputField, NoData } from "../../components";
import API from "../../axios";

export default function Settings() {
    PageTitle("Settings");
    const [record, setRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [formData, setFormData] = useState();

    useEffect(() => {
        getRecord();
    }, []);

    const getRecord = () => {
        setIsLoading(true);
        API('get', 'admin/settings').then(_json => {
            let data = _json.data.detail;
            setRecord(data);
            var newData = {};
            data.map(item => {
                newData[item.title] = item.values
            })
            setFormData(newData);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    const handleChange = (e) => {
        var { name, value } = e.target
        setFormData({ ...formData, [name]: value });
    }

    const updateRecord = (e) => {
        setUpdateLoader(true);
        API('post', 'admin/update-setting',formData).then(_json => {
            getRecord();
            setUpdateLoader(false);
        }).catch(err => {
            setUpdateLoader(false);
        })
    }

    return (
        <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography component="h6" variant="h6">
                            Settings
                        </Typography>
                        <Button
                            disabled={updateLoader}
                            onClick={updateRecord} variant="contained">
                            {updateLoader && <div style={{ marginRight: '10px' }} ><CircularProgress size={22} color="primary" /></div>}update
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#333' }}>
                            <TableRow>
                                <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading && <TableRow>
                                <TableCell colSpan='2'>
                                    <Stack
                                        justifyContent='center'
                                        alignItems='center'
                                    >
                                        <CircularProgress size={22} color="primary" />
                                    </Stack>
                                </TableCell>
                            </TableRow>}
                            {!(!!record) && !isLoading && <TableRow>
                                <TableCell colSpan='2'>
                                    <NoData />
                                </TableCell>
                            </TableRow>}
                            {!!record && !isLoading &&
                                <>
                                    <TableRow>
                                        <TableCell>Fees type</TableCell>
                                        <TableCell>
                                            <Select
                                                variant="standard"
                                                name="fees_type"
                                                onChange={handleChange}
                                                fullWidth
                                                value={formData?.fees_type ?? 'percentage'}
                                            >
                                                <MenuItem value='percentage'>Percentage</MenuItem>
                                                <MenuItem value='fixed'>Fixed</MenuItem>
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Fee percent</TableCell>
                                        <TableCell>
                                            <InputField
                                                name="fee_percent"
                                                initValue={formData?.fee_percent ?? '0.0'}
                                                handleChange={handleChange}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stripe fee type</TableCell>
                                        <TableCell>
                                            <Select
                                                variant="standard"
                                                name="stripe_fees_type"
                                                onChange={handleChange}
                                                fullWidth
                                                value={formData?.stripe_fees_type ?? 'percentage'}
                                            >
                                                <MenuItem value='percentage'>Percentage</MenuItem>
                                                <MenuItem value='fixed'>Fixed</MenuItem>
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stripe fee percent</TableCell>
                                        <TableCell>
                                            <InputField
                                                name="stripe_fee_percent"
                                                initValue={formData?.stripe_fee_percent ?? '0.0'}
                                                handleChange={handleChange}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stripe fees on stripe fees type</TableCell>
                                        <TableCell>
                                            <Select
                                                variant="standard"
                                                name="stripe_fees_on_stripe_fees_type"
                                                onChange={handleChange}
                                                fullWidth
                                                value={formData?.stripe_fees_on_stripe_fees_type ?? 'percentage'}
                                            >
                                                <MenuItem value='percentage'>Percentage</MenuItem>
                                                <MenuItem value='fixed'>Fixed</MenuItem>
                                            </Select>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Stripe fees on stripe fees value</TableCell>
                                        <TableCell>
                                            <InputField
                                                name="stripe_fees_on_stripe_fees_value"
                                                initValue={formData?.stripe_fees_on_stripe_fees_value ?? '0.0'}
                                                handleChange={handleChange}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </>
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Container>
    );
}
