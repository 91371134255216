import { Box, Button, CircularProgress, Container, Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import API from "../../axios";
import { NoData } from "../../components";
import PageTitle from "../../hooks/page-title";
import SingleTransaction from "./single-transaction";

export default function Transactions() {
    PageTitle('Transactions');
    const [singleRecord, setSingleRecord] = useState(null);
    const [page, setPage] = useState(1);
    const [record, setRecord] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({
        name: "",
        totalAmount: "",
        dateFrom: "",
        dateTo: ""
    })
    console.log(search)
    React.useEffect(() => {
        getRecord();
    }, [page]);

    const getRecord = () => {
        setIsLoading(true);
        API("get", `admin/transactions?page=${page}`)
            .then((_json) => {
                setRecord(_json.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    function handleChange(event) {
        const { name, value } = event.target
        setSearch(prevFormData => {
            return {
                ...prevFormData,
                [name]: value
            }
        })
    }
    console.log(record)
    function searchHandler() {
        const { name, totalAmount, dateFrom, dateTo } = search
        setIsLoading(true);

        API("get", `admin/transactions?total_amount=${totalAmount ?? ""}&created_from=${dateFrom ?? ""}&name=${name ?? ""}&created_to=${dateTo ?? ""}&per_page=10&page=${page}`)
            .then((_json) => {
                setRecord(_json.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    const colSpan = 4;

    return (
        <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
            {singleRecord != null ?
                <SingleTransaction _item={singleRecord} goBack={setSingleRecord} />
                :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="h6" variant="h6" mb={2}>
                            Transactions
                        </Typography>
                        <Typography component="h6" variant="h6" mb={0}>
                            Filters
                        </Typography>
                        <Grid gap={1} sx={{ display: "flex", marginBottom: "10px", alignItems: 'flex-end', flexWrap: 'wrap' }}>
                            <Stack direction="row" flexWrap="wrap" alignItems='flex-end' gap={1} sx={{ flexWrap: 'wrap' }}>
                                <TextField
                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                    size="small"
                                    id="one"
                                    label="Name"
                                    name="name"
                                    value={search.name}
                                    onChange={handleChange}
                                />
                                <TextField
                                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                                    size="small"
                                    id="two"
                                    value={search.totalAmount}
                                    name='totalAmount'
                                    label="Amount"
                                    onChange={handleChange}
                                />
                                <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                                    <Typography component="p" variant="p" mb={1}>
                                        From
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type='date'
                                        size="small"
                                        id="three"
                                        value={search.dateFrom}
                                        name='dateFrom'
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
                                    <Typography component="p" variant="p" mb={1}>
                                        to
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type='date'
                                        size="small"
                                        id="four"
                                        value={search.dateTo}
                                        name='dateTo'
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Stack>
                            <Stack direction="row" gap={1} sx={{ pt: { xs: 1, sm: 0 } }}>
                                <Button variant="outlined" onClick={searchHandler}>Apply</Button>
                                <Button variant="outlined" onClick={() => {
                                    setSearch({
                                        name: "",
                                        totalAmount: "",
                                        dateFrom: "",
                                        dateTo: ""
                                    })
                                    getRecord()
                                }}>Clear</Button>
                            </Stack>
                        </Grid>
                        <Box component='div' sx={{ overflow: "auto" }}>
                            <Table sx={{ minWidth: "500px" }}>
                                <TableHead sx={{ backgroundColor: "#333" }}>
                                    <TableRow>
                                        <TableCell sx={{ color: "#fff" }}>From</TableCell>
                                        <TableCell sx={{ color: "#fff" }}>To</TableCell>
                                        <TableCell sx={{ color: "#fff" }}>Total Amount</TableCell>
                                        <TableCell sx={{ color: "#fff" }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {isLoading && (
                                        <TableRow>
                                            <TableCell colSpan={colSpan}>
                                                <Stack justifyContent="center" alignItems="center">
                                                    <CircularProgress size={22} color="primary" />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {record?.data.length === 0 && !isLoading && (
                                        <TableRow>
                                            <TableCell colSpan={colSpan}>
                                                <NoData />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!!record?.data && !isLoading &&
                                        record.data.map((_v, _i) => {
                                            return (
                                                <TableRow key={_i}>
                                                    <TableCell>{_v.coin.sender.name}</TableCell>
                                                    <TableCell>{_v.coin.given_friend.name}</TableCell>
                                                    <TableCell>{_v.total_amount}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="text"
                                                            onClick={() => { setSingleRecord(_v) }}
                                                        >
                                                            View
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                        {!!record && record.last_page > 1 && (
                            <Box component="div" sx={{ mt: 2 }}>
                                <Pagination
                                    count={Math.ceil(record.total / record.per_page)}
                                    page={page}
                                    onChange={(event, value) => {
                                        setPage(value);
                                    }}
                                    sx={{ color: "primary.main" }}
                                />
                            </Box>
                        )}
                    </Grid>
                </Grid>}
        </Container>
    );
}
