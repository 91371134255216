import React from "react";
import { useNavigate } from "react-router-dom";
import {
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    useTheme,
    useMediaQuery,
    Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import CategoryIcon from '@mui/icons-material/Category';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SettingsIcon from '@mui/icons-material/Settings';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

import { Logo } from "../../assets";
import API from "../../axios";
import StoreContext from "../../store/store";

export default function Aside({ open, toggleDrawer }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    const [expOpen, setExpOpen] = React.useState(true);
    const {_dispatch} = React.useContext(StoreContext);

    const handleClick = () => {
        setExpOpen(!expOpen);
    };

    const signOut = () => {
        API("get", 'admin/logout');
        localStorage.clear();
        _dispatch({ type: 'LOGOUT' });
    };

    const changeRoute = (r) => {
        return navigate(r);
    };

    return (
        <>
            <Drawer
                variant={isMdUp ? "permanent" : "temporary"}
                anchor="left"
                open={open}
                onClose={toggleDrawer}
            >
                <List sx={{ mt: 2 }}>
                    <ListItem>
                        <Box sx={{ margin: "0 auto" }}>
                            <img src={Logo} alt="logo" width="150px" />
                        </Box>
                    </ListItem>
                </List>
                <div style={{ margin: "1rem 0" }}>
                    <Divider />
                </div>
                <List className="listItemStyle">
                    <NavItem
                        text="Home"
                        path='/'
                        myFunction={() => changeRoute("/")}
                        icon={
                            <HomeIcon
                                sx={{ color: "secondary.dark" }}
                            />
                        }
                    />
                    <NavItem
                        text="Transactions"
                        myFunction={() => changeRoute("/transactions")}
                        path='/transactions'
                        icon={
                            <PriceCheckIcon
                                sx={{ color: "secondary.dark" }}
                            />
                        }
                    />
                    <NavItem
                        text="Categories"
                        myFunction={() => changeRoute("/categories")}
                        path='/categories'
                        icon={
                            <CategoryIcon
                            sx={{ color: "secondary.dark" }}
                            />
                        }
                        />
                    <NavItem
                        text="Coins"
                        myFunction={() => changeRoute("/coins")}
                        path='/coins'
                        icon={
                            <CurrencyExchangeIcon
                            sx={{ color: "secondary.dark" }}
                            />
                        }
                        />
                    <NavItem
                        text="Settings"
                        myFunction={() => changeRoute("/settings")}
                        path='/settings'
                        icon={
                            <SettingsIcon
                                sx={{ color: "secondary.dark" }}
                            />
                        }
                    />
                    <NavItem
                        text="Logout"
                        myFunction={signOut}
                        icon={<LogoutIcon sx={{ color: "secondary.dark" }} />}
                    />
                </List>
            </Drawer>
        </>
    );
}

const NavItem = (props) => {
    var routeName = window.location.pathname;
    return (
        <ListItem sx={{ padding: 0 }}>
            <ListItemButton
                onClick={props.myFunction}
                selected={props.path === routeName}
                sx={linkStyles}
            >
                <ListItemIcon>{props.icon}</ListItemIcon>
                <ListItemText primary={props.text} sx={{ color: "secondary.main" }} />
            </ListItemButton>
        </ListItem>
    );
};

const linkStyles = {
    '&.Mui-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '& *': {
            color: 'primary.main',
            transition: '0.2s all ease-in-out'
        },
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '& *': {
                color: 'primary.main',
            }
        },
        '&::before': {
            height: '100%',
        },
    },
    '&::before': {
        content: `''`,
        position: 'absolute',
        top: '50%',
        right: '0',
        transform: 'translateY(-50%)',
        height: '0',
        width: '4px',
        backgroundColor: 'primary.main',
        transition: '0.2s all ease-in-out'
    },
    '&:hover::before': {
        height: '100%',
    },
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '& *': {
            color: 'primary.main',
            transition: '0.2s all ease-in-out'
        }
    }
}
{/* 
<ListItemButton
                        onClick={handleClick}
                        sx={{ color: "secondary.dark" }}
                    >
                        <ListItemIcon>
                            <InboxIcon sx={{ color: "secondary.dark" }} />
                        </ListItemIcon>
                        <ListItemText primary="Settings" sx={{ color: "secondary.main" }} />
                        {expOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>  
<Collapse in={expOpen} timeout="auto" unmountOnExit>
<List component="div" sx={{ padding: 0 }}>
    <ListItemButton
        sx={{ pl: 4 }}
        onClick={() => changeRoute("/")}
    >
        <ListItemIcon>
            <CategoryIcon sx={{ color: "secondary.dark" }} />
        </ListItemIcon>
        <ListItemText
            primary="Logs"
            sx={{ color: "secondary.main" }}
        />
    </ListItemButton>
    <ListItemButton
        sx={{ pl: 4 }}
        onClick={() => changeRoute("/")}
    >
        <ListItemIcon>
            <ListIcon sx={{ color: "secondary.dark" }} />
        </ListItemIcon>
        <ListItemText
            primary="Profile"
            sx={{ color: "secondary.main" }}
        />
    </ListItemButton>
</List>
</Collapse>    */}