import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Stack,
    TextField,
    Box,
    MenuList,
    Paper,
} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckIcon from '@mui/icons-material/Check';

import { InputField } from "../../components";
import API from "../../axios";

export default function CoinForm({ item = null, submissionComplete = () => { } }) {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        amount: "",
        logo_image: null,
        categories: [],
        type: "ADD",
        recordID: null
    });
    const [categories, setCategories] = React.useState(null);
    const [selectedImage, setSelectedImage] = React.useState(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    useEffect(() => {
        if (!!item) {
            setFormData({
                ...formData,
                amount: item.amount,
                categories: item.categories,
                recordID: item.coin_listing_id,
                type: "EDIT",
            });
            setSelectedImage(item.image_url);
        } else {
            setSelectedImage(null);
        }
        getCategories();
    }, []);

    const getCategories = () => {
        API('get', 'admin/active-categories').then(_json => {
            setCategories(_json.data);
        }).catch(err => {
            console.error("🚀 err", err.response)
        })
    }

    const handleChange = (e) => {
        var { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImage = (e) => {
        var [file] = e.target.files;
        setFormData({ ...formData, logo_image: file });
    };

    const handleSelected = (item) => {
        let arr = formData.categories;
        if (arr.length) {
            let objIndex = arr.findIndex(x => x.category_id === item.category_id)
            if (objIndex < 0) {
                arr.push(item);
                setFormData({ ...formData, categories: arr });
            } else {
                let filterArr = arr.filter(x => x.category_id !== item.category_id);
                setFormData({ ...formData, categories: filterArr });
            }
        } else {
            arr.push(item);
            setFormData({ ...formData, categories: arr });
        }
    }

    const checkValues = (_v) => {
        let flag = false;
        if (formData.categories.length > 0) {
            let item = formData.categories.filter(x => x.category_id === _v.category_id);
            if (item.length) {
                flag = true;
            } else {
                flag = false;
            }
        }
        return flag;
    }

    const handleForm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let _url =
            formData.type === "ADD"
                ? "admin/coin-list"
                : `admin/update-coin-list/${formData.recordID}`;
        let _fd = new FormData();
        if (formData.categories.length === 0) {
            alert("please choose category")
            setIsLoading(false)
            return
        }
        if (!!formData.amount) {
            _fd.append('amount', formData.amount)
        }
        if (!!formData.logo_image) {
            _fd.append('logo_image', formData.logo_image)
        }
        if (formData.categories.length) {
            formData.categories.forEach((v, i) => {
                _fd.append(`categories[${i}]`, v.category_id);
            });
        }
        API("post", _url, _fd)
            .then((_json) => {
                setIsLoading(false);
                submissionComplete();
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    return (
        <form autoComplete="off" onSubmit={handleForm}>
            {!!selectedImage &&
                <Box sx={{ mb: 2 }}>
                    <img src={selectedImage} alt="" width='45px' />
                </Box>
            }
            <InputField
                label="Amount"
                name="amount"
                type="number"
                required
                placeholder="Amount"
                initValue={formData.amount}
                handleChange={handleChange}
            />
            <Stack
                direction={{ sm: 'row' }}
                alignItems={{ sm: 'center' }}
            >
                <Box sx={{
                    width: { sm: '48%', sx: '100%' }
                }}>
                    <input
                        required
                        type='file'
                        name="logo_image"
                        onChange={handleImage}
                        accept="image/*"
                        style={{
                            width: '100%',
                            flexGrow: 1,
                            marginTop: '16px',
                            border: '1px solid #ccc',
                            padding: '7px 10px',
                            borderRadius: '5px',
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        flexGrow: 1,
                        mt: 2,
                        ml: { sm: 1 },
                        width: { sm: '48%', sx: '100%' },
                        zIndex: "200"
                    }}
                >
                    <Button
                        variant="text"
                        endIcon={
                            menuOpen ?
                                <ExpandLessIcon />
                                :
                                <ExpandMoreIcon />
                        }
                        onClick={() => setMenuOpen(!menuOpen)}
                        sx={{
                            textTransform: 'none',
                            color: '#000',
                            width: '100%',
                            display: 'flex',
                            fontWeight: 'normal',
                            justifyContent: 'space-between',
                            border: '1px solid #ccc',
                            '&:hover': {
                                backgroundColor: 'transparent'
                            }
                        }}
                    >
                        Select Categories
                    </Button>
                    {menuOpen &&
                        <Box component={Paper}
                            sx={{
                                position: 'absolute',
                                top: '40px',
                                left: 0,
                                width: '100%'
                            }}
                        >
                            <MenuList>
                                {!(!!categories) &&
                                    <MenuItem
                                        disabled>
                                        No Categories Found
                                    </MenuItem>
                                }
                                {!!categories && categories.map((_v, _i) => {
                                    return <MenuItem
                                        key={_i}
                                        onClick={() => handleSelected(_v)}
                                    >
                                        {checkValues(_v) && <CheckIcon sx={{ color: 'primary.main', mr: 1 }} />}
                                        {_v.title}
                                    </MenuItem>
                                })}
                            </MenuList>
                        </Box>
                    }
                </Box>
            </Stack>
            <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                    mt: 2
                }}
            >
                {isLoading && (
                    <div style={{ marginLeft: "10px" }}>
                        <CircularProgress size={22} color="primary" />
                    </div>
                )}
                {formData.type === "ADD" ? "Add" : "Save"}
            </Button>
        </form>
    );
}
