import React, { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
} from "@mui/material";

export default function InputField({ label = '',
    initValue = null,
    handleChange = (e) => { },
    styles,
    name = '', type = 'text',
    error='',
    ...props }) {

    const [value, setValue] = useState('');

    React.useEffect(() => {
        if (!!initValue) {
            setValue(initValue);
        }else{
            setValue('');
        }
        console.log('there');
    }, [initValue]);

    return (
        <FormControl sx={{ width: "100%",...styles }} variant="standard">
            <InputLabel htmlFor={name}>
                {label}
            </InputLabel>
            <Input
                id={name}
                error={error!==''}
                type={type}
                name={name}
                value={value}
                onChange={(event) => {
                    setValue(event.target.value);
                    handleChange(event);
                }}
                {...props}
            />
            {error!==''&&<FormHelperText sx={{color:'primary.main'}} >{error}</FormHelperText>}
        </FormControl>
    );
}