import './App.css';
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Router from './router/router';
import StoreContext from './store/store';
import appReducer, { initialState } from './store/reducer';
import { useReducer } from 'react';

const theme = createTheme({
  palette: {
    primary: {
      main: "#D83454",
      light: "#D8345478",
    },
    secondary: {
      light: '#FFFFFF',
      main: '#8E8E8E',
      dark: '#262626',
    },
  },
});

export default function App() {
  const [state, dispatch] = useReducer(appReducer, initialState);
  return (
    <StoreContext.Provider value={{ _state: state, _dispatch: dispatch }}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </StoreContext.Provider>
  );
}