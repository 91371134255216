export const initialState = {
    user: null,
    token: null,
    isLogged: false,
}

const appReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case "LOGIN":
            return { ...state, user: payload.user, token: payload.token, isLogged: true }
        case "LOGOUT":
            return { ...state, user: null, token: null, isLogged: false }
        default:
            throw new Error('Error: Unknown type ' + type);
    }
}

export default appReducer;