import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Pagination,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";

import PageTitle from "../../hooks/page-title";
import { NoData } from "../../components";
import API from "../../axios";
import CoinForm from "./coin-form";

export default function Coins() {
  PageTitle("Coins");
  const [showForm, setShowForm] = useState(false);
  const [singleRecord, setSingleRecord] = useState(null);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toggleForm = () => setShowForm(!showForm);

  useEffect(() => {
    getRecord();
  }, [page]);

  const getRecord = () => {
    setIsLoading(true);
    API("get", `admin/coin-lists?page=${page}`)
      .then((_json) => {
        setRecord(_json.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const updateRecord = (item) => {
    setSingleRecord(item);
    setShowForm(true);
  };

  const addRecord = () => {
    if (!!singleRecord) {
      setSingleRecord(null);
    }
    toggleForm();
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="h6" variant="h6">
              Coins
            </Typography>
            <Button onClick={addRecord} variant="contained">
              {!showForm ? "Add" : "Cancel"}
            </Button>
          </Stack>
        </Grid>
        {showForm && (
          <Grid item xs={12}>
            <CoinForm
              item={singleRecord}
              submissionComplete={() => {
                getRecord();
                toggleForm();
              }}
            />
          </Grid>
        )}
        {!showForm && (
          <Grid item xs={12} sx={{ overflow: "auto" }}>
            <Table sx={{ minWidth: "500px" }}>
              <TableHead sx={{ backgroundColor: "#333" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>Image</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Amount</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <TableCell colSpan="4">
                      <Stack justifyContent="center" alignItems="center">
                        <CircularProgress size={22} color="primary" />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
                {record?.data.length === 0 && !isLoading && (
                  <TableRow>
                    <TableCell colSpan="4">
                      <NoData />
                    </TableCell>
                  </TableRow>
                )}
                {!!record?.data && !isLoading &&
                  record.data.map((_v, _i) => {
                    return (
                      <TableRow key={_i}>
                        <TableCell><img src={_v.image_url} alt="" width="55px" /></TableCell>
                        <TableCell>{_v.amount}</TableCell>
                        <TableCell>
                          <CircleIcon
                            sx={{
                              fontSize: 12,
                              color: _v.active ? "green" : "red",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="text"
                            onClick={() => updateRecord(_v)}
                          >
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!!record && record.last_page > 1 && (
              <Box component="div" sx={{ mt: 2 }}>
                <Pagination
                  count={Math.ceil(record.total / record.per_page)}
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                  }}
                  sx={{ color: "primary.main" }}
                />
              </Box>
            )}
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
