import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Pagination,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';

import PageTitle from "../../hooks/page-title";
import { InputField } from "../../components";
import API from "../../axios";
import CategoriesForm from "./categories-form";

export default function Categories() {
  PageTitle("Categories");
  const [showForm, setShowForm] = useState(false);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(null);
  const [singleRecord, setSingleRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toggleForm = () => setShowForm(!showForm);

  useEffect(() => {
    getRecord();
  }, [page]);

  const getRecord = () => {
    setIsLoading(true);
    API('get', `admin/categories?page=${page}`).then(_json => {
      setRecord(_json.data);
      setIsLoading(false);
    }).catch(err => {
      setIsLoading(false);
    })
  }

  const updateRecord = (item) => {
    setShowForm(true);
    setSingleRecord(item);
  }

  const addRecord = () => {
    toggleForm();
    if (!singleRecord) {
      setSingleRecord(null);
    }
  }

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="h6" variant="h6">
              Categories
            </Typography>
            <Button onClick={addRecord} variant="contained">
              {!showForm ? "Add" : "Cancel"}
            </Button>
          </Stack>
        </Grid>
        {showForm && (
          <Grid item xs={12}>
            <CategoriesForm
              item={singleRecord}
              formSubmit={() => {
                getRecord();
                setShowForm(false);
              }}
            />
          </Grid>
        )}
        {!showForm &&
          <Grid item xs={12} sx={{ overflow: "auto" }}>
            <Table sx={{ minWidth: "420px" }}>
              <TableHead sx={{ backgroundColor: '#333' }}>
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Status</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <TableRow>
                  <TableCell colSpan='3'>
                    <Stack
                      justifyContent='center'
                      alignItems='center'
                    >
                      <CircularProgress size={22} color="primary" />
                    </Stack>
                  </TableCell>
                </TableRow>}
                {!record?.data.length && !isLoading && <TableRow>
                  <TableCell colSpan='3'>
                    <Typography
                      variant="p"
                      component="p"
                      sx={{ textAlign: 'center', opacity: 0.7, fontWeight: 'bold' }}
                    >
                      No Data
                    </Typography>
                  </TableCell>
                </TableRow>}
                {record?.data.length &&
                  record.data.map((_v, _i) => {
                    return (
                      <TableRow key={_i}>
                        <TableCell>{_v.title}</TableCell>
                        <TableCell>
                          <CircleIcon sx={{ fontSize: 12, color: _v.active ? 'green' : 'red' }} />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="text"
                            onClick={() => updateRecord(_v)}
                          >
                            <EditIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!!record && record.last_page > 1 &&
              <Box
                component='div'
                sx={{ mt: 2 }}
              >
                <Pagination count={Math.ceil(record.total / record.per_page)} page={page} onChange={(event, value) => {
                  setPage(value);
                }} sx={{ color: 'primary.main' }} />
              </Box>
            }
          </Grid>
        }
      </Grid>
    </Container>
  );
}
