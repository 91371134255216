import React, { useState, useEffect } from "react";
import {
    Button,
    CircularProgress,
    Stack,
    Switch,
} from "@mui/material";

import { InputField } from "../../components";
import API from "../../axios";

export default function CategoriesForm({ item = null,formSubmit=()=>{} }) {
    const [formData, setFormData] = useState({
        title: '',
        status: true,
        recordID: null,
        type: 'ADD'
    });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!!item) {
            setFormData({
                title: item.title,
                status: item.active,
                recordID: item.category_id,
                type: 'EDIT',
            })
        }
    }, [item]);

    const handleInput = (e) => {
        var { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleForm = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let _url = formData.type === "ADD" ? 'admin/category' : `admin/update-category/${formData.recordID}`;
        let _data = {
            title: formData.title,
            status: formData.status,
        }
        API('post', _url, _data).then(_json => {
            formSubmit();
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    return (
        <form autoComplete="off" onSubmit={handleForm}>
            <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-start"
                spacing={2}
            >
                <InputField
                    label="Title"
                    name="title"
                    required
                    placeholder="Title"
                    initValue={formData.title}
                    handleChange={handleInput}
                />
                {formData.type === "EDIT" &&
                    <Switch
                        checked={formData.status}
                        onChange={() => {
                            setFormData({ ...formData, status: !formData.status })
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                }
                <Button variant="contained"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading && <div style={{ marginLeft: '10px' }}><CircularProgress size={22} color="primary" /></div>}
                    {formData.type === "ADD" ? 'Add' : 'Save'}</Button>
            </Stack>
        </form>
    );
}
