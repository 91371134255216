import React, { useState, useEffect } from "react";
import { Box, Container, CircularProgress, Grid, Paper, Typography } from "@mui/material";

import PageTitle from "../../hooks/page-title";
import API from "../../axios";

export default function Home() {
  PageTitle('Home');

  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRecord();
  }, []);

  const getRecord = () => {
    setIsLoading(true);
    API("get", 'admin/home')
      .then((_json) => {
        setRecord(_json.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <Container maxWidth="false" sx={{ pt: 3, pb: 3 }} >
      <Grid container spacing={2}>

        <Grid item sm={6} xs={12}>
          <CardItem title='Users' value={record?.users} isLoading={isLoading} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CardItem title='Coins' value={record?.coins} isLoading={isLoading} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CardItem title='Categories' value={record?.categories} isLoading={isLoading} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <CardItem title='Transactions' value={record?.transactions} isLoading={isLoading} />
        </Grid>

      </Grid>
    </Container>
  );
}

const CardItem = ({ title, value=0, isLoading }) => {
  return (
    <Box maxWidth='100%' component={Paper}
      sx={{
        padding: 4,

      }}
    >
      <Typography
        variant='h5'
        component='h5'
        sx={{
          color: 'secondary.main',
          fontWeight: 'bold'
        }}
      >
        {title}
      </Typography>
      <Typography
        variant='h5'
        component='h5'
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          textAlign: 'end'
        }}
      >
        {isLoading ?
          <CircularProgress size={22} color="primary" />
          :
          value
        }
      </Typography>
    </Box>
  );
}