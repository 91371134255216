import React from "react";
import { Box, Fab, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function SingleTransaction({ _item, goBack }) {
    const stripe = JSON.parse(_item.stripe_response)
    const tResp = JSON.parse(_item.transfer_response)
    console.log(_item)
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Fab size="small" onClick={() => goBack()}
                    sx={{
                        mb: 2
                    }}
                >
                    <ArrowBackIcon />
                </Fab>
                <Box component='div'>
                    <Typography component="h6" variant="h6" mb={2}>
                        Coin
                    </Typography>
                    <img width="50px" src={_item?.coin?.coin_image_url} />
                    <Typography component="p" variant="p" mb={2}>
                        Amount : {_item?.coin?.amount ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Status : {_item?.coin?.status ?? ''}
                    </Typography>
                    <Typography component="h6" variant="h6" mb={2}>
                        Transaction details
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Total amount : {_item?.total_amount ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Transaction date : {_item?.created_at.substr(0, 10) ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Transfer status : {_item?.transfer_successfull ? "Successfull" : "Declined"}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Fees type : {_item?.fees_type === 'percentage' ? "Percentage" : ""}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Platform percentage : {_item?.platform_percentage ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Platform share : ${_item?.platform_share ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Stripe fee on stripe fee share : ${_item?.stripe_fee_on_stripe_fee_share ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Stripe fees on stripe fee value : ${_item?.stripe_fee_on_stripe_fee_value ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Stripe fees percentage : {_item?.stripe_fees_percentage ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Stripe fees share : ${_item?.stripe_fees_share ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={2}>
                        Stripe fees type : {_item?.stripe_fees_type === 'percentage' ? "Percentage" : ""}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
                <MyCard>
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems='center'
                        width="100%"
                        sx={{ marginBottom: '10px' }}
                    >
                        <Typography component="h5" variant="h5" mb={2}>
                            Sender
                        </Typography>
                        <img alt="img" style={{ borderRadius: '5px' }} width="75" src={_item?.coin?.sender?.banner_image_url ?? ''} />
                    </Stack>
                    <PrintRow
                        title1='Name'
                        value1={_item?.coin?.sender?.name ?? ''}
                        title2='Phone'
                        value2={_item?.coin?.sender?.phone ?? ''}
                    />
                    <Typography component="p" variant="p" mb={1}>
                        Username: {_item?.coin?.sender?.username ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Email: {_item?.coin?.sender?.email ?? ''}
                    </Typography>
                    <PrintRow
                        title1='Total friends'
                        value1={_item?.coin?.sender?.total_friends ?? ''}
                        title2='Email status'
                        value2={_item?.coin?.sender?.email_verified ? 'Verified' : "Not verified" ?? ''}
                    />
                    <Typography component="p" variant="p" mb={1}>
                        userID: {_item?.coin?.sender?.user_id ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Stripe status: {_item?.coin?.sender?.profile_completed_on_stripe ? "Verified" : "Not Verified" ?? ''}
                    </Typography>
                </MyCard>
            </Grid>
            <Grid item sm={6} xs={12}>
                <MyCard>
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems='center'
                        width="100%"
                        sx={{ marginBottom: '10px' }}
                    >
                        <Typography component="h5" variant="h5" mb={2}>
                            Reciever
                        </Typography>
                        <img alt="img" style={{ borderRadius: '5px' }} width="75" src={_item?.coin?.given_friend?.banner_image_url ?? ''} />
                    </Stack>
                    <PrintRow
                        title1='Name'
                        value1={_item?.coin?.given_friend?.name ?? ''}
                        title2='Phone'
                        value2={_item?.coin?.given_friend?.phone ?? ''}
                    />
                    <Typography component="p" variant="p" mb={1}>
                        Username: {_item?.coin?.given_friend?.username ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Email: {_item?.coin?.given_friend?.email ?? ''}
                    </Typography>
                    <PrintRow
                        title1='Total friends'
                        value1={_item?.coin?.given_friend?.total_friends ?? ''}
                        title2='Email Status'
                        value2={_item?.coin?.given_friend?.email_verified ? 'Verified' : "Not verified" ?? ''}
                    />
                    <Typography component="p" variant="p" mb={1}>
                        userID: {_item?.coin?.given_friend?.user_id ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Stripe status: {_item?.coin?.given_friend?.profile_completed_on_stripe ? "Verified" : "Not Verified" ?? ''}
                    </Typography>
                </MyCard>
            </Grid>

            {/* <Grid item xs={12}>
                <Box component='div'>
                    <Typography component="h6" variant="h6" mb={2}>
                        Stripe:
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Total amount: {stripe.amount ?? ''}$
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Description: {stripe.description ?? ''}$
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Status: {stripe.status ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Dispute status: {stripe.disputed ? "Disputed" : "Not disputed" ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        id: {stripe.id ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Currency: {stripe.currency ?? ''}
                    </Typography>
                    <Typography sx={{ wordWrap: "anywhere" }} component="p" variant="p" mb={1}>
                        Receipt URL: <a href={stripe.receipt_url ?? ''}>{stripe.receipt_url ?? ''}</a>
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Refund status: {stripe.refuneded ? "Refunded" : "Not refunded" ?? ''}
                    </Typography>
                    <Typography component="h6" variant="h6" sx={{ fontSize: '15px' }} mb={2} mt={2}>
                        Payment method details:
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Card: {stripe.payment_method_details.card.brand ?? ''}
                    </Typography>
                    <Typography component="p" variant="p" mb={1}>
                        Country: {stripe.payment_method_details.card.country ?? ''}
                    </Typography>
                </Box>
            </Grid> */}

            <Grid item xs={12} md={6}>
                <Box component='div'>
                    <Typography component="h6" variant="h6" mb={2}>
                        Stripe:
                    </Typography>
                    <TableContainer>
                        <pre style={{ whiteSpace: "pre-wrap" }}>
                            {JSON.stringify(stripe, null, 2)}
                        </pre>
                    </TableContainer>
                </Box>
            </Grid>

            <Grid item xs={12} md={6}>
                <Box component='div'>
                    <Typography component="h6" variant="h6" mb={2}>
                        Transfer status:
                    </Typography>
                    <TableContainer>
                        <pre>
                            {JSON.stringify(tResp, null, 2)}
                        </pre>
                    </TableContainer>
                </Box>
            </Grid>
        </Grid>
    );
}

const MyCard = ({ children }) => {
    return (
        <Box component='div'
            sx={{
                border: '1px solid #ccc',
                padding: '20px',
                borderRadius: '7px',
                height: "100%"
            }}
        >
            {children}
        </Box>
    )
}

const PrintRow = ({ title1, value1, title2, value2 }) => {
    return (
        <Stack
            direction={{ xs: 'column' }}
            md={{ flexDirection: "column" }}
            alignItems='flex-start'
        >
            <Typography component="p" variant="p" sx={{ flexGrow: 1 }} mb={1}>
                {title1} : {value1}
            </Typography>
            <Typography component="p" variant="p" sx={{ flexGrow: 1 }} mb={1}>
                {title2} : {value2}
            </Typography>
        </Stack>
    )
}