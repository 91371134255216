import React, { useState } from 'react';
import {
    IconButton,
    InputAdornment,
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function PasswordInputField({
    label = '',
    initValue = '',
    handleChange = (e) => { },
    name = '',
    error='',
    ...props }) {

    const [value, setValue] = useState('');
    const [secureEntry, setSecureEntry] = React.useState(true);

    React.useEffect(() => {
        if (initValue !== '') {
            setValue(initValue);
        }
    }, []);

    return (
        <FormControl sx={{ width: "100%" }} variant="standard">
            <InputLabel htmlFor={name}>
                {label}
            </InputLabel>
            <Input
                id={name}
                type={secureEntry ? "password" : "text"}                
                name={name}
                error={error!==''}
                value={value}
                onChange={(event) => {
                    setValue(event.target.value);
                    handleChange(event)
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setSecureEntry(!secureEntry)}
                        >
                            {secureEntry ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                {...props}
            />
            {error!==''&&<FormHelperText sx={{color:'primary.main'}} >{error}</FormHelperText>}
        </FormControl>
    );
}