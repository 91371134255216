import React from "react";
import {Aside,Header} from "../components";

export default function Layout({ children }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  return (
    <div className="az_laytout_99">
      <Aside open={open} toggleDrawer={toggleDrawer} />
      <div className="az_main">
        <Header toggleDrawer={toggleDrawer} />
        <main className="main_content">{children}</main>
      </div>
    </div>
  );
}
